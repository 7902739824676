




















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'
import controller from '@/app/ui/controllers/PublicVoucherController'
import { Utils } from '@/app/infrastructures/misc'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import { PUBLIC_VOUCHER_HISTORY_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { EnumVoucherPurpose, VOUCHER_PURPOSES } from '@/app/infrastructures/misc/Constants/publicVoucher'
import { IParameter, IParameterMultipleCode } from '@/data/infrastructures/misc/interfaces/publicVoucher'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { IHeaderCell } from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import { IDataCell } from '@/app/infrastructures/misc/Constants/scheduleOrder'
import TextInput from '@/app/ui/components/TextInput/index.vue'

dayjs.extend(dayjsUTC)

type fakeDictionary<T> = { [key: string]: T }

@Component({
  name: "MultipleVoucherCode",
  components: {
    TextInput,
    EmptyState,
    DataTableV2,
    DateTimePickerV2,
    DataTable,
    DownloadIcon,
    PaginationNav,
    Button,
  },
})
export default class MultipleVoucherCode extends Vue {
  controller = controller
  form: {
    startDate: Date | undefined
    endDate: Date | undefined
  } = {
    startDate: undefined,
    endDate: undefined,
  }
  parameters: IParameterMultipleCode = {
    page: 1,
    perPage: PUBLIC_VOUCHER_HISTORY_PAGINATION,
    startDate: null,
    endDate: null,
    keyword: '',
    publicVoucherId: null,
  }
  headersVal = [
    this.headerCellMapper('Voucher Name', '230px'),
    this.headerCellMapper('Customer ID', '160px'),
    this.headerCellMapper('Customer Name', '240px'),
    this.headerCellMapper('Voucher Purpose', '240px'),
    this.headerCellMapper('Voucher Code', '180px'),
    this.headerCellMapper('Payment Date', '160px'),
    this.headerCellMapper('Redeem Date', '160px'),
    this.headerCellMapper('% Discount', '120px'),
    this.headerCellMapper('Voucher Amount', '180px'),
    this.headerCellMapper('Usage Date', '160px'),
    this.headerCellMapper('Usage Amount', '180px'),
    this.headerCellMapper('Parcel Poin Redeemed', '180px'),
  ]
  hasData = false
  isInit = true

  created(): void {
    const queries = <Record<string, never>>this.$route.query
    this.parameters = {
      page: Utils.alwaysNumber(queries.page) || 1,
      perPage:
        Utils.alwaysNumber(queries.perPage) ||
        PUBLIC_VOUCHER_HISTORY_PAGINATION,
      startDate: queries.startDate,
      endDate: queries.endDate,
      keyword: queries.keyword,
      publicVoucherId: queries.publicVoucherId,
    }

    this.fetchList()
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | boolean | undefined,
    colWidth: string
  ): IDataCell {
    if (value === undefined) {
      value = '-'
    }

    return {
      value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  get headers(): IHeaderCell[] {
    return this.headersVal
  }

  get params(): IParameterMultipleCode {
    return {
      ...this.parameters,
      startDate: this.parameters.startDate
        ? dayjs(this.parameters.startDate, 'YYYY-MM-DD')
        .utc()
        .toISOString()
        : null,
      endDate: this.parameters.endDate
        ? dayjs(this.parameters.endDate, 'YYYY-MM-DD')
        .endOf('days')
        .utc()
        .toISOString()
        : null,
      sortBy: 'NEWEST',
    }
  }

  get paramsWithoutID(): IParameter {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      startDate: this.parameters.startDate
        ? dayjs(this.parameters.startDate, 'YYYY-MM-DD')
        .utc()
        .toISOString()
        : null,
      endDate: this.parameters.endDate
        ? dayjs(this.parameters.endDate, 'YYYY-MM-DD')
        .endOf('days')
        .utc()
        .toISOString()
        : null,
      sortBy: 'NEWEST',
    }
  }

  get historyData(): Array<Array<unknown>> {
    const data: unknown[][] = []
    if (controller.multipleVouchers && controller.multipleVouchers.length > 0) {
      this.hasData = true

      controller.multipleVouchers.forEach((val) => {
        const percentage = val.voucherPercentage ? val.voucherPercentage + '%' : '-'
        const purpose = VOUCHER_PURPOSES.find(p => p.value === val.purpose)

        const amountPercentage = `Rp${Utils.currencyDigit(val.voucherAmount || 0)}`
        const amountParcelPoint = `Rp${Utils.currencyDigit(val.amount || 0)}`
        const usageAmountPercentage = `Rp${Utils.currencyDigit(val.vouchedUsed || 0)}`
        const usageAmountParcelPoint = `Rp${Utils.currencyDigit(val.usedAmount || 0)}`

        let amount = amountPercentage
        if (purpose && purpose.value && purpose.value == EnumVoucherPurpose.PARCEL_POINT) {
          amount = amountParcelPoint
        }

        const finalData: unknown[] = [
          this.tableCellMapper(val.name, '230px'),
          this.tableCellMapper(val.customerId, '160px'),
          this.tableCellMapper(val.customerName, '240px'),
          this.tableCellMapper(purpose?.label, '240px'),
          this.tableCellMapper(val.code, '180px'),
          // Payment Date
          this.tableCellMapper(
            Utils.formatDate(val.purchaseAt || '', 'DD/MM/YYYY', '-'), '160px'
          ),
          // Redeem Date
          this.tableCellMapper(
            Utils.formatDate(val.createdAt || '', 'DD/MM/YYYY', '-'), '160px'
          ),
          this.tableCellMapper(percentage, '120px'),
          this.tableCellMapper(amount, '180px'),
          this.tableCellMapper(
            Utils.formatDate(val.vouchedUsedAt || '', 'DD/MM/YYYY', '-'), '160px'
          ),
          this.tableCellMapper(usageAmountPercentage, '180px'),
          this.tableCellMapper(usageAmountParcelPoint, '180px'),
        ]

        data.push(finalData)
      })
    }
    return data
  }

  private onStartDateChange(date: Date): void {
    this.form.startDate = date
    if (this.form.startDate && date) {
      this.parameters.startDate = Utils.formatDate(date.toLocaleDateString())
    } else {
      this.parameters.startDate = undefined
    }

    if (this.form.endDate) {
      const startDate = Utils.formatDate(date.toLocaleDateString(), 'DD-MMMM-YYYY')
      const endDate = Utils.formatDate(this.form.endDate.toLocaleDateString(), 'DD-MMMM-YYYY')
      if (this.form.endDate < date && startDate !== endDate) {
        this.form.endDate = undefined
        this.parameters.endDate = undefined
      }
    }
  }

  private onEndDateChange(date: Date): void {
    this.form.endDate = date
    if (this.form.endDate && date) {
      this.parameters.endDate = Utils.formatDate(date.toLocaleDateString())
    } else {
      this.parameters.endDate = undefined
    }
  }

  @Watch('params')
  onParamsChanged(val: fakeDictionary<IParameterMultipleCode>): void {
    const formatDate = 'YYYY-MM-DD'
    this.$router.replace({
      query: {
        ...val,
        startDate: this.parameters.startDate
          ? Utils.formatDate(this.parameters.startDate, formatDate)
          : null,
        endDate: this.parameters.endDate
          ? Utils.formatDate(this.parameters.endDate, formatDate)
          : null,
      },
    })
  }

  private fetchList(resetPage?: boolean): void {
    this.isInit = false

    if (resetPage) {
      this.parameters.page = 1
    }

    controller.getMultipleVouchers({
      params: this.params,
    })
  }

  private getExportUrl(): void {
    if (this.params.publicVoucherId && this.params.publicVoucherId > 0) {
      controller.getExportedFileV2({
        params: this.paramsWithoutID,
        id: String(this.params.publicVoucherId),
      })
      return
    }

    controller.getExportFileMultipleVoucher({
      params: this.params,
    })
  }
}
